@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary text-lg scroll-smooth;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-dark;
  }
  .title,
  .section-title {
    @apply text-5xl lg:text-5xl mb-5 font-bold;
  }

  .h2 {
    @apply text-4xl lg:text-6xl font-bold;
  }
  .pretitle {
    @apply text-lg lg:text-[22px] text-light mb-4 lg:mb-6 uppercase font-normal;
  }
  .lead {
    @apply text-2xl text-light font-normal mb-6;
  }
  .link {
    @apply text-lg text-dark;
  }
  .section {
    @apply py-8 lg:py-[142px];
  }
  .btn {
    @apply rounded-lg transition flex justify-center items-center;
  }
  .btn-lg {
    @apply h-[75px] px-6 text-2xl font-normal;
  }
  .btn-md {
    @apply h-[60px] px-[16px] text-lg font-normal;
  }
  .btn-sm {
    @apply h-[48px] px-[16px] text-lg font-normal;
  }
  .btn-accent {
    @apply text-white bg-accent hover:bg-accentHover;
  }
  .btn-white {
    @apply text-accent bg-white hover:bg-white/90;
  }
  .btn-outline {
    @apply bg-transparent text-accent border border-accent hover:bg-accent hover:text-white;
  }
  .btn-link {
    @apply text-accent text-xl lg:text-2xl;
  }
}

@media (max-width: 768px) {
  .font-media {
    font-size: 40px;
  }

  .btn-media {
    display: none;
  }
}

.img-profil img {
  width: 350px;
  height: 600px;
  margin-top: 20px;
  border-radius: 20px; /* Atur nilai ini sesuai preferensi Anda */
  overflow: hidden;
}

.tengah h2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
